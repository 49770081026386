import moment from 'moment';
import { PRODUCTS } from './constants-products';
import { useEffect, useState } from 'react';
import SlackAlias from '@buddieshr/components/slackAlias';

export const capitalize = (t) =>
  `${t[0].toUpperCase()}${t.substring(1, t.length).toLowerCase()}`;

export const getRandomArbitrary = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const isLocalhost = () =>
  window && window.location && window.location.hostname === 'localhost';

export const showMarketingBannerHRDay = () => {
  const dateCheck = moment(new Date()).format('DD/MM') === '26/09';
  return dateCheck;
};

export const showMarketingBannerInternationHRDay = () => {
  const dateCheck = moment(new Date()).format('DD/MM') === '20/05';
  return dateCheck;
};

export const getNbInstallForToday = () => {
  // try to get nbInstalls from localstorage (to avoid having a different nb on refresh)
  // if found, then return
  // if not then compute new number, store and return
  const nbInstallList = JSON.parse(localStorage.getItem('number_installs'));
  const today = moment().format('DD/MM/YYYY');
  if (nbInstallList && nbInstallList[today]) {
    return nbInstallList[today];
  } else {
    const nbInstall = getRandomArbitrary(122, 455);
    localStorage.setItem(
      'number_installs',
      JSON.stringify({
        ...(nbInstallList || []),
        [today]: nbInstall,
      })
    );
    return nbInstall;
  }
};

export function slugify(str) {
  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
}

export const gtmVirtualPageView = (rest) => {
  window.dataLayer?.push({
    event: 'VirtualPageView',
    ...rest,
  });
};

export const getSubscribeUrl = (isDev, appName) => {
  let product = PRODUCTS.find((f) => f.slug === appName);
  if (product) {
    return isDev ? product.subscribeDevUrl : product.subscribeUrl;
  }
  return null;
};

export const sortReviews = (reviews) =>
  reviews.sort((a, b) => {
    const amoment = moment(a.date);
    const bmoment = moment(b.date);
    if (amoment.isBefore(bmoment)) {
      return 1;
    } else if (bmoment.isBefore(amoment)) {
      return -1;
    } else return 0;
  });

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export const getInstallLink = (refSource, installLink) =>
  `${installLink}/?ref=${refSource}`;

export const getPluralListOfElement = (elements) =>
  elements.map((name, index) => (
    <>
      <SlackAlias key={`${name}_${index}`} name={name} />
      {elements.length > 1 &&
        index < elements.length - 1 &&
        index >= elements.length - 2 && <span> and </span>}
      {elements.length > 1 && index < elements.length - 2 && <span>, </span>}
    </>
  ));

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return '';
};

export const scrollTo = (selector) =>
  document.querySelector(selector).scrollIntoView({
    behavior: 'smooth',
  });
export const numberWithComma = (value) => value.toLocaleString('en-US');

export const getTotalCostPerEmployee = (baseSalary) => {
  // Source https://sparkbay.com/en/culture-blog/calculate-cost-turnover-3

  // Loos of knowledge is 5% of base salary per year of tenure
  const AVERAGE_YEAR_OF_TENURE = 3;
  const LOSS_OF_KNOWLEDGE = 0.05 * baseSalary * AVERAGE_YEAR_OF_TENURE;

  // LOSS PRODUCTIVITY FROM OTHER EMPLOYEES FILLING IN FOR THE VACANT POSITION
  const PRODUCTIVITY_COST = 0.3;
  const PRODUCTIVITY_COST_VALUE = PRODUCTIVITY_COST * (baseSalary * 0.2);

  const HIRING_MANAGER_SALARY = 68000;
  const WORK_HOURS_PER_YEAR = 2000;
  const COST_PER_HOUR = HIRING_MANAGER_SALARY / WORK_HOURS_PER_YEAR;
  const WORK_HOURS_TO_FILL_POSITION = 30;
  const ADVERTISING_COST = 1000;

  const COST_TO_HIRE_REPLACEMENT =
    ADVERTISING_COST + COST_PER_HOUR * WORK_HOURS_TO_FILL_POSITION;

  const MENTOR_SALARY = 100000;
  const TRAINING_HOURS = 45;
  const NEW_HIRE_TRAINING_COST =
    (MENTOR_SALARY / WORK_HOURS_PER_YEAR) * TRAINING_HOURS;

  const NEW_HIRE_REDUCTION_PRODUCTIVITY = 0.25;
  const WEEKS_TO_GO_BACK_NORMAL = 25;
  const SALARY_PER_WEEK = baseSalary / 52;
  const LOSS_PER_WEEK = SALARY_PER_WEEK * NEW_HIRE_REDUCTION_PRODUCTIVITY;
  const RETURN_TO_PREVIOUS_PRODUCTIVITY =
    LOSS_PER_WEEK * WEEKS_TO_GO_BACK_NORMAL;

  const TOTAL_PER_EMPLOYEE =
    LOSS_OF_KNOWLEDGE +
    PRODUCTIVITY_COST_VALUE +
    COST_TO_HIRE_REPLACEMENT +
    NEW_HIRE_TRAINING_COST +
    RETURN_TO_PREVIOUS_PRODUCTIVITY;

  return TOTAL_PER_EMPLOYEE;
};

export default {};
